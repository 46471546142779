@import-normalize;

html,
body,
#root,
#main {
  width: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.code {
  font-family: "Source Code Pro", Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.serif {
  font-family: "Source Serif Pro", "Georgia", "Times New Roman", Times, serif;
}

/*font-family: 'Source Code Pro', monospace;
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Source Serif Pro', serif;
*/
